<template>
    <vx-card :title="'Create Unloading Document'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Request Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(requestDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date 
                    :value="this.unloadingDate"
                    @selected="(val) => { this.unloadingDate = val }"
                    :add-days="1"
                ></Date>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="3%"></th>
                            <th width="30%">SKU Description</th>
                            <th width="10%">HU</th>
                            <th width="15%">Batch</th>
                            <th width="7%">Qty Received</th>
                            <th width="7%">Qty UOM</th>
                            <th width="10%">HU</th>
                            <th width="10%">Qty Sales</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(skuLine, index) in skuLines">
                            <!-- first row -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding-top: 11px;" :rowspan="skuLine.add_line.length">
                                    <vs-checkbox
                                        :checked="checked.includes(skuLine.canvas_handover_line_id)"
                                        v-on:click="addChecked(skuLine.canvas_handover_line_id)"
                                    ></vs-checkbox>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="skuLine.add_line.length">
                                    <vs-input :value="skuLine.selected_sku ? skuLine.selected_sku.label : ''" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="skuLine.add_line.length">
                                    <vs-input v-model="skuLine.selected_hu" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="skuLine.add_line.length">
                                    <vs-input v-model="skuLine.batch" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="skuLine.add_line.length">
                                    <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="skuLine.add_line.length">
                                    <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <v-select 
                                        :filterable="false"
                                        :clearable="false"
                                        :options="skuLine.ul_option_hu"
                                        v-model="skuLine.add_line[0].ul_selected_hu"
                                        @input="(val) => { onChangeHu(val, index, 0); }"
                                        :disabled="!checked.includes(skuLine.canvas_handover_line_id)"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input 
                                        type="number" 
                                        class="w-full" 
                                        v-model="skuLine.add_line[0].ul_qty"
                                        :min="0"
                                        :disabled="!checked.includes(skuLine.canvas_handover_line_id)"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="skuLine.add_line.length > 1"
                                            @click.stop="removeRow(index, 0)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row"
                                        />
                                        <vs-button v-if="skuLine.add_line.length == 1"
                                            @click.stop="addRow(index)" size="small" color="success"
                                            icon-pack="feather" icon="icon-plus"
                                            title="Add Row"
                                        />
                                    </div>
                                </td>
                            </tr>

                            <template v-for="(addLine, idx) in skuLines[index].add_line">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + idx" v-if="idx > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select 
                                            :filterable="false"
                                            :clearable="false"
                                            :options="skuLine.ul_option_hu"
                                            v-model="addLine.ul_selected_hu"
                                            @input="(val) => { onChangeHu(val, index, idx); }"
                                            :disabled="!checked.includes(skuLine.canvas_handover_line_id)"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input 
                                            type="number" 
                                            class="w-full" 
                                            v-model="addLine.ul_qty"
                                            :min="0"
                                            :disabled="!checked.includes(skuLine.canvas_handover_line_id)"
                                        ></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="skuLine.add_line.length > 0"
                                                @click.stop="removeRow(index, idx)" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row"
                                            />
                                            <vs-button v-if="skuLine.add_line.length == idx + 1"
                                                @click.stop="addRow(index)" size="small" color="success"
                                                icon-pack="feather" icon="icon-plus"
                                                title="Add Row"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Date from "./component/Date.vue";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        Date,
        vSelect,
        flatPickr
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            handoverCode: null,
            optionWarehouse: [],
            selectedWarehouse: null,
            optionTerritory: [],
            selectedTerritory: null,
            optionSales: [],
            selectedSales: null,
            requestDate: null,
            loadingDate: null,
            unloadingDate: null,
            checked: [],
            skuLines: [
                {
                    canvas_handover_line_id: 0,
                    selected_sku: null,
                    selected_hu: null,
                    batch: null,
                    qty: 0,
                    qty_uom: 0,
                    ul_option_hu: [],
                    add_line: [
                        {
                            ul_selected_hu: null,
                            ul_qty: 0,
                        }
                    ],
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/canvas/unloading/request/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.code = resp.data.code
                    this.handoverCode = resp.data.handover_code
                    this.selectedWarehouse = resp.data.selected_warehouse.label
                    this.selectedTerritory = resp.data.selected_territory.label
                    this.selectedSales = resp.data.selected_sales.label
                    this.requestDate = moment(resp.data.request_date).toDate()
                    this.loadingDate = moment(resp.data.loading_date).toDate()
                    this.skuLines = resp.data.sku_lines
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                console.log(error)
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.sales-view",
            });
        },
        handleSubmit() {
            if (!this.unloadingDate) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Unloading Date is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let skus = []
            this.skuLines.forEach((val) => {
                let addLines = []
                if (this.checked.includes(val.canvas_handover_line_id)) {
                    val.add_line.forEach(idx => {
                        addLines.push({
                            ul_item_unit: idx.ul_selected_hu,
                            ul_quantity: parseInt(idx.ul_qty),
                        })
                    })
                    skus.push({
                        canvas_handover_line_id: val.canvas_handover_line_id,
                        add_lines: addLines,
                    })
                }
            })

            let formData = new FormData();
            formData.append("unloading_date", this.unloadingDate
                ? moment(this.unloadingDate).format("YYYY-MM-DD")
                : null)
            formData.append("skus", JSON.stringify(skus))

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to send this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/unloading/create-document/" + this.id, formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                            this.draw++;
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: error,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    });
                }
            });
        },
        onChangeHu(val, index, idx) {
            this.skuLines[index].add_line[idx].ul_selected_hu = val
        },
        addChecked(val) {
            if (this.checked.includes(val)) {
                this.checked.splice(this.checked.indexOf(val), 1);
            } else {
                this.checked.push(val);
            }
        },
        openTab(uri) {
            window.open(uri)
        },
        removeRow(index, idx) {
            this.skuLines[index].add_line.splice(idx, 1);
        },
        addRow(index) {
            this.skuLines[index].add_line.push({
                ul_selected_hu: null,
                ul_qty: 0,
            })
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
}
</script>